<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">About <nobr>{{ this.myTitle }}</nobr></h1>       
          <div style='position: absolute; bottom: 0; text-align: right; width: 100%; padding-right: 10pt; font-size: 10pt; font-weight:bold;'>
  			{{getCopyright()}}
  </div>   
        </div>
      </div>
    </div>
    <div class="container">
      <p class="org-description is-size-4">
        {{appName()}} <br/>
        Version/UI: {{versionUI()}} <br>
        Version/Backend: {{version()}} <span style='font-size: 8pt;'>{{builtDate()}}</span><br>
        <br/>
        <b>Tech Stack:</b>
        <ul>
			<li><A target="_blank" href="https://www.wildfly.org">JBoss/Wildfly AppServer <span style='font-size: 10pt;'>{{wildflyVersion}}</span></A></li>	
			<li><A target="_blank" href="https://openjdk.org">Java Backend <span style='font-size: 10pt;'>{{jdkVersion}}</span></A></li>
			<li><A target="_blank" href="https://vuejs.org">Vue.js Frontend</A></li>
        	<li><A target="_blank" href="https://timefold.ai">Timefold optimizer (open source AI solver)</A></li>
			<li><A target="_blank" href="https://ignite.apache.org">Apache Ignite distributed database for High Performance <span style='font-size: 10pt;'>{{igniteVersion}}</span></A></li>
        	<li><A target="_blank" href="https://www.postgresql.org/">PostgreSQL Data-storage</A></li>
			<li><A target="_blank" href="https://www.maxmind.com">GeoLite2 data created by MaxMind</A></li>
			<li><A target="_blank" href="https://opennlp.apache.org">Apache OpenNLP language processing</A></li>
        </ul>
       </p>
    </div>
  </div>
</template>
<script>
// import axios from 'axios';
import { myTimer, receiveToken, HTTP, showError, myName, serviceAPI} from '../variables.js';
import { getCopyright } from '../utils.js';
import { getAppStyle, getAppStyleFG, setDarkMode, initAppMode, hexToRgb, isDark, getBG, getBGColor } from '@/AppStyle.js';

export default {
  name: 'about',
  data() {
      return {
  		getCopyright,
  		igniteVersion: "",
  		wildflyVersion: "",
  		jdkVersion: ""
  	
      }
  },
  methods:{
  	  appName(x) { return myName; },
      version() { return sessionStorage.serviceVersion; },
      versionUI() { return "0.1.2.1" },
      builtDate() { return sessionStorage.builtDate; },
      identity(x) { return x; },
      ignite() {
    	  
    	  let that = this;
    	  HTTP.post( serviceAPI+"/getIgniteVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
          .then( response => 
          { 
				this.igniteVersion = response.data.ignite;
				this.jdkVersion = response.data.java;
				this.wildflyVersion = response.data.wildfly;

          }).catch(e => { this.igniteVersion = "";  this.jdkVersion= ""; this.wildflyVersion= "";});
    	  /*
    	  HTTP.post( serviceAPI+"/getWildflyVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
          .then( response => 
          {  
				alert( JSON.stringify(response.data))
          }).catch(e => { showError(that.$toast, e, "") });
    	  */
    	  
      }
  },
  created()
  {
	this.ignite();
	initAppMode();
  }
 }
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
</style>